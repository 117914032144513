<template>
  <!-- 预付费配置-编辑-新增 -->
  <div class="dataMigration-wrapper">
    <!--主体内容-->
    <div class="content">
      <div class="topback">
        <el-button @click="back" class="saveButton" size="medium" type="primary">返回</el-button>
      </div>
      <div class="contentImtem">
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          :rules="rules"
          ref="form"
          class="demo-form-inline"
        >
          <div class="conentLayout">
            <!-- <div>
              <span class="tipicon"></span
              ><b class="tiptitle tipOne">添加路内停车场</b>
            </div> -->
            <div class="tipdetailOne">
              <el-form-item prop="configName" label="配置名称：">
                <el-input
                  maxlength="160"
                  v-model="formInline.configName"
                  placeholder="请输入配置名称"
                ></el-input>
              </el-form-item>
              <el-form-item prop="operationId" label="所属商户：">
                <el-select
                  v-model.trim="formInline.operationId"
                  filterable
                  size="15"
                  @change="changeDiCi"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="conentLayout">
            <span class="tipicon"></span><b class="tiptitle">自主入场模式</b>
            <div class="tipdetail tipdetailTop">
              PDA泊位、地磁泊位开启自主入场后，车主在车场的有效范围内可以自主入场，车主可以在移动端进行自主停车，查看<span
                class="blueColor"
                @click="dialogVisible = true"
                >移动端呈现效果图</span
              >。
              <el-switch
                class="switch"
                v-model="formInline.selfEntry"
                :active-value="1"
                :inactive-value="0"
                active-color="#527BFF"
                inactive-color="#A3A3A3"
              >
              </el-switch>
            </div>
            <div v-if="formInline.selfEntry == 1" class="tipdetail">
              <el-form-item label="配置有限范围" prop="entryDistance" style="padding: 15px 0px">
                <el-input class="inputStyle" v-model="formInline.entryDistance"></el-input
                ><span class="unit">米</span>
              </el-form-item>
              <p class="introduce">
                有效范围，指划定车主可以进行自主入场的区域，即以车场所设经纬度为圆心，有效范围为半径的区域。取值范围
                [ 100,10000 ]。
              </p>
            </div>
            <div class="tipdetail">
              <el-form-item
                label="配置免费时长"
                prop="freeTime"
                style="padding: 15px 0px; margin-left: 6px"
              >
                <el-input class="inputStyle" v-model="formInline.freeTime"></el-input
                ><span class="unit">分钟</span>
              </el-form-item>
              <p class="introduce">
                此参数仅用于“仅限免费时长启动PDA审核”配合使用，取值范围 [ 0,240 ]；如果用户停车时长超过配置分钟数，PDA无需确认，反之，用于停车时长不足配置分钟数据PDA需要确认“是否以用户【申请/支付】驶离时间作为车辆驶离时间”。
              </p>
            </div>
          </div>
          <div class="conentLayout">
            <span class="tipicon"></span><b class="tiptitle">自主离场模式</b>
            <p class="tipdetail tipdetailTop">
              <!-- 自主驶离有支付驶离和申请驶离两种模式，两种模式择一。 -->
              <el-switch
                class="switch"
                v-model="formInline.selfExit"
                :active-value="1"
                :inactive-value="0"
                active-color="#527BFF"
                inactive-color="#A3A3A3"
              >
              </el-switch>
            </p>
            <div v-if="formInline.selfExit == 1">
              <!-- <el-form-item
                class="lineCLick"
                prop="exitType"
                style="padding-left: 34px; padding-top: 10px"
              >
                <el-radio-group
                  @change="changeLabel"
                  v-model="formInline.exitType"
                >
                  <el-radio :label="1">支付驶离</el-radio>
                  <el-radio :label="2">申请驶离</el-radio>
                </el-radio-group>
              </el-form-item>
              <div class="line"></div> -->
              <!-- <p class="tipdetail" v-if="formInline.exitType == 2">
                开放申请驶离车场后，车主可以在移动端申请驶离，查看<span
                  class="blueColor"
                  @click="dialogVisible2 = true"
                  >移动端呈现效果图</span
                >。
              </p> -->
              <p class="tipdetail">
                开启【申请驶离】车主可以在移动端自主驶离，查看<span
                  class="blueColor"
                  @click="dialogVisible1 = true"
                  >移动端呈现效果图</span
                >。开启【场中支付】车主可以在移动端支付驶离,<span
                  class="blueColor"
                  @click="dialogVisible2 = true"
                  >移动端呈现效果图</span
                >。
              </p>
              <!-- <div v-if="formInline.exitType == 1" class="tipdetail">
                <el-form-item
                  label="配置驶离时长"
                  prop="exitFreeTime"
                  style="padding-top: 20px"
                >
                  <el-input
                    class="inputStyle"
                    v-model="formInline.exitFreeTime"
                  ></el-input
                  ><span class="unit">分钟</span>
                </el-form-item>
                <p class="introduce" style="padding: 20px 0px">
                  驶离时长，指车主完成支付驶离到驶离车场的时间长，即设置预留车主免费驶离的时长。取值范围
                  [ 0，15 ] 。
                </p>
              </div> -->
              <div style="padding: 10px 0px 5px 34px; color: #333; font-size: 12px">
                配置驶离规则：
              </div>
              <el-form-item class="tipdetail configRule" prop="exitRule">
                <el-radio-group class="exitRileGroup" v-model="formInline.exitRule">
                  <el-form-item class="radioRule">
                    <el-radio :label="1">PDA全程审核</el-radio>
                    <p class="introduce" v-show="formInline.exitRule == 1">
                      车主自主驶离，由PDA确认“是否以用户【申请/支付】驶离时间作为车辆驶离时间”
                    </p>
                  </el-form-item>
                  <el-form-item class="radioRule">
                    <el-radio :label="2">仅限免费时长启动PDA审核</el-radio>
                    <p class="introduce" v-if="formInline.exitRule == 2">
                      在免费时长内，车主自主驶离，由PDA确认“是否以用户【申请/支付】驶离时间作为车辆驶离时间”
                    </p>
                  </el-form-item>
                  <el-form-item class="radioRule">
                    <el-radio :label="3">信赖车主</el-radio>
                    <p class="introduce" v-if="formInline.exitRule == 3">
                      信赖车主，以车主自主驶离的【申请/支付】时间为停车记录的截止时间
                    </p>
                  </el-form-item>
                  <el-form-item class="radioRule" v-if="showDici">
                    <el-radio :label="4">取信地磁</el-radio>
                    <p class="introduce" v-if="formInline.exitRule == 4">
                      取信地磁，以地磁上报驶离的时间为停车记录的截止时间，车主驶离即生成欠费订单；视频泊位不支持取信地磁离场
                    </p>
                  </el-form-item>
                </el-radio-group>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
      <div class="save">
        <el-button @click="submit" class="saveButton" type="primary">{{ $t('button.preservation') }}</el-button>
        <el-button @click="back" class="cancle">取消</el-button>
      </div>
    </div>
    <div
      class="mask"
      v-show="dialogVisible"
      :style="{ 'z-index': '10000', 'background-image': `url(${picUrl})` }"
      @click="dialogVisible = false"
    ></div>
    <div
      class="mask"
      v-show="dialogVisible1"
      :style="{ 'z-index': '10000', 'background-image': `url(${picUrl1})` }"
      @click="dialogVisible1 = false"
    ></div>
    <div
      class="mask"
      v-show="dialogVisible2"
      :style="{ 'z-index': '10000', 'background-image': `url(${picUrl2})` }"
      @click="dialogVisible2 = false"
    ></div>
  </div>
</template>

<script>
export default {
  name: "addeditPrepaidConfig",
  data() {
    let exitFreeTime = (rule, value, callback) => {
      if (value != parseInt(value)) {
        callback(new Error("只能为整数"));
      }
      if (value < 0 || value > 15) {
        callback(new Error("取值范围为0 ~ 15"));
      } else {
        callback();
      }
    };
    let freeTime = (rule, value, callback) => {
      if (value != parseInt(value) && value != "") {
        callback(new Error("只能为整数"));
      }
      if (value < 0 || value > 240) {
        callback(new Error("取值范围为0 ~ 240"));
      } else {
        callback();
      }
    };
    let entryDistance = (rule, value, callback) => {
      if (value != parseInt(value)) {
        callback(new Error("只能为整数"));
      }
      if (value < 100 || value > 10000) {
        callback(new Error("取值范围为100 ~ 10000"));
      } else {
        callback();
      }
    };
    return {
      showDici: 0,
      picUrl: require("./parkDetail.png"),
      picUrl1: require("./001.png"),
      picUrl2: require("./002.png"),
      input: "10",
      preChargeSettingId: "",
      radioConfig: "1",
      value: true,
      value1: true,
      type: "add",
      tenantList: [],
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      selfParkingId: "",
      isEdit: "",
      // entryDistance: "", // 有效范围
      formInline: {
        configName: "", // 预付费配置名称
        operationId: "", // 运营商ID
        selfEntry: "", // 0关闭 1开启
        selfExit: "", // 0关闭 1开启
        exitType: 1, // 支付驶离 1   申请驶离2
        entryDistance: "", // 自主入场范围
        exitFreeTime: "", // 支付驶离时长
        exitRule: "", // 驶离规则
        freeTime: "", // 免费时长
      },
      rules: {
        configName: [
          {
            required: true,
            message: "请输入配置名称",
            trigger: "change",
          },
        ],
        operationId: [
          {
            required: true,
            message: "请选择所属商户",
            trigger: "change",
          },
        ],
        entryDistance: [
          {
            required: true,
            message: "请配置有效范围",
            trigger: "blur",
          },
          {
            validator: entryDistance,
            trigger: "blur",
          },
        ],
        exitFreeTime: [
          {
            required: true,
            message: "请配置驶离时长",
            trigger: "change",
          },
          {
            validator: exitFreeTime,
            trigger: "blur",
          },
        ],
        freeTime: [
          // {
          //   required: true,
          //   message: "请配置免费时长",
          //   trigger: "change",
          // },
          {
            validator: freeTime,
            trigger: "blur",
          },
        ],
        exitType: [
          {
            required: true,
            message: "请选择驶离方式",
            trigger: "change",
          },
        ],
        exitRule: [
          {
            required: true,
            message: "请配置驶离规则",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    changeLabel() {
      this.$forceUpdate();
    },
    getEntryDistance(e) {
      console.log(e.target.value, "是多少");
    },
    submit() {
      console.log(this.formInline, "this.formInline");
      let data = {};
      if (this.type === "add") {
        data = this.formInline;
      } else {
        data = {
          selfParkingId: this.selfParkingId,
          ...this.formInline,
        };
      }
      let url = this.type === "add" ? "/acb/2.0/selfParking/save" : "/acb/2.0/selfParking/update";
      let type = this.type == "add" ? "post" : "post";
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$axios[type](url, {
            data: data,
          }).then((res) => {
            if (res.state == 0) {
              this.$message({
                message: res.desc,
                type: "success",
              });
              this.$router.go(-1);
            } else {
              this.$alert(res.desc, "提示");
            }
          });
        }
      });
    },

    // 返回上一页
    back() {
      this.$router.go(-1);
    },
    /* 商户名称的下拉框-筛选后的值 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    /* 地磁是否展示 */
    getshowDici(operationId) {
      this.$axios
        .get("/acb/2.0/selfParking/trustDc", {
          data: {
            operationId: operationId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.showDici = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    changeDiCi(e) {
      console.log("e", e);
      if (e) {
        this.getshowDici(e);
      } else {
      }
    },
    /* 商户名称的下拉框-全部 */
    getAllTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 查询
    searchData() {
      this.$axios
        .get("/acb/2.0/selfParking/getBySelfParkingId", {
          data: {
            selfParkingId: this.selfParkingId,
          },
        })
        .then((res) => {
          if (res.state === 0) {
            this.formInline.configName = res.value.configName;
            this.formInline.operationId = res.value.operationId;
            this.formInline.selfEntry = res.value.selfEntry;
            this.formInline.selfExit = res.value.selfExit;
            this.formInline.exitType = res.value.exitType;
            this.formInline.entryDistance = res.value.entryDistance;
            this.formInline.exitFreeTime = res.value.exitFreeTime;
            this.formInline.exitRule = res.value.exitRule;
            this.formInline.freeTime = res.value.freeTime;
            console.log(this.formInline, "this.formInline");
            this.getshowDici(res.value.operationId);
          }
        });
    },
  },
  created() {
    // this.getTenantList();
  },
  mounted() {
    console.log(this.$route, "route");
    this.type = this.$route.query.type ? this.$route.query.type : this.type;
    this.selfParkingId = this.$route.query.selfParkingId ? this.$route.query.selfParkingId : "";
    // 编辑
    if (this.type === "edit") {
      console.log(this.$route.query, "this.$route.query");
      this.searchData();
      this.getAllTenantList();
    } else {
      this.getTenantList();
    }
  },
};
</script>
<style lang="stylus" scoped>
>, .dataMigration-wrapper {
  // background: #F4F7F9;
}
</style>
<style scoped lang="stylus" rel="stylesheet/stylus">
.widthinput {
  width: 60px;
  height: 40px;
}

.content {
  padding: 0 24px;

  .save {
    padding-top: 24px;
    text-align: center;
  }
}

>>>.el-form-item__label {
  font-size: 12px;
}

>>>.inputStyle {
  width: 195px;
  height: 32px;
}

>>> .el-radio__label {
  font-size: 12px;
}

>>>.el-button {
  width: 96px;
  border-radius: 4px;
}

.saveButton {
  background: #527BFF;
}

.cancle {
  background: #fff;
  color: #527BFF;
  border: 1px solid #527BFF;
}

.unit {
  padding-left: 9px;
  font-size: 12px;
}

.configRule {
  width: 100%;
}

>>>.configRule>.el-form-item__content {
  // display: flex;
  width: 100%;
}
.exitRileGroup
  // display: flex;
.radioRule {
  // flex: 1;
  display: block;
}

.configRule p {
  font-size: 12px;
  color: #A3A3A3;
  line-height: 1.5;
  padding-left: 24px;
}

.parkC {
  position: relative;
}

.parkDetail {
  position: absolute;
  width: 375px;
  height: 665px;
  left: 45%;
  margin-top: 5%;
  // margin-left: -188px;
}

.parkClose {
  position: absolute;
  left: 68%;
  margin-top: 6%;
  z-index: 1;
}

.contentImtem {
  margin-top: 55px;
}

.contentImtem .el-form .el-form-item {
  margin-bottom: 0px;
}

.mask {
  background: rgba(49, 53, 65, 0.6) no-repeat center / contain;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  overflow: auto;
}

.conentLayout {
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 8px;
  padding: 16px;
  font-size: 12px;
  min-height: 50px;
}

.tipicon {
  width: 4px;
  height: 15px;
  background: #0C66FF;
  display: inline-block;
}

.tiptitle {
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2F2F51;
  line-height: 20px;
  height: 20px;
  padding-left: 13px;
}

.tipOne {
  font-weight: bold;
}

.switch {
  float: right;
}

.blueColor {
  font-size: 12px;
  color: rgba(12, 102, 255, 1);
  cursor: pointer;
}

.introduce {
  color: #A3A3A3;
  font-size: 12px;
}

.tipdetailOne {
  padding-left: 34px;
  padding-top: 5px;
}

.tipdetail {
  padding-left: 34px;
  color: #333;
  font-weight: 400;
  font-size: 12px;
  // padding: 10px 14px 10px 34px;
}

.tipdetailTop {
  padding-top: 19px;
}

.line {
  height: 1px;
  margin-left: 34px;
  background: #EAEAEA;
  margin-bottom: 19px;
}

.lineCLick .is-checked {
  &::before {
    content: '';
    position: absolute;
    right: 10px;
    top: 26px;
    height: 2px;
    width: 40px;
    background-color: #0C66FF;
  }
}

.topback {
  float: right;
  margin-top: -46px;
  background: #527bff;
}
</style>
